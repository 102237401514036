import { j as n } from "./jsx-runtime-B6kdoens.js";
import { _ as u } from "./index-CWsRuimy.js";
import * as m from "react";
import { forwardRef as $, useState as x, createElement as l, useEffect as v } from "react";
import { $ as j, a as N } from "./index-Cp-Nu9_j.js";
import { $ as R } from "./index-B5chcmfZ.js";
import { $ as b } from "./index-B--ic68G.js";
import { T as i } from "./Tooltip-ae2E5kx6.js";
import { c as p } from "./utils-CJ9afRe1.js";
import { c as I } from "./index-BV2fynjD.js";
const A = "Avatar", [C, W] = j(A), [T, h] = C(A), y = /* @__PURE__ */ $((a, r) => {
  const { __scopeAvatar: e, ...t } = a, [o, s] = x("idle");
  return /* @__PURE__ */ l(T, {
    scope: e,
    imageLoadingStatus: o,
    onImageLoadingStatusChange: s
  }, /* @__PURE__ */ l(b.span, u({}, t, {
    ref: r
  })));
}), E = "AvatarImage", M = /* @__PURE__ */ $((a, r) => {
  const { __scopeAvatar: e, src: t, onLoadingStatusChange: o = () => {
  }, ...s } = a, d = h(E, e), c = P(t), f = R((g) => {
    o(g), d.onImageLoadingStatusChange(g);
  });
  return N(() => {
    c !== "idle" && f(c);
  }, [
    c,
    f
  ]), c === "loaded" ? /* @__PURE__ */ l(b.img, u({}, s, {
    ref: r,
    src: t
  })) : null;
}), k = "AvatarFallback", F = /* @__PURE__ */ $((a, r) => {
  const { __scopeAvatar: e, delayMs: t, ...o } = a, s = h(k, e), [d, c] = x(t === void 0);
  return v(() => {
    if (t !== void 0) {
      const f = window.setTimeout(
        () => c(!0),
        t
      );
      return () => window.clearTimeout(f);
    }
  }, [
    t
  ]), d && s.imageLoadingStatus !== "loaded" ? /* @__PURE__ */ l(b.span, u({}, o, {
    ref: r
  })) : null;
});
function P(a) {
  const [r, e] = x("idle");
  return v(() => {
    if (!a) {
      e("error");
      return;
    }
    let t = !0;
    const o = new window.Image(), s = (d) => () => {
      t && e(d);
    };
    return e("loading"), o.onload = s("loaded"), o.onerror = s("error"), o.src = a, () => {
      t = !1;
    };
  }, [
    a
  ]), r;
}
const w = y, V = M, z = F, q = I(
  "relative flex shrink-0 overflow-hidden rounded-full",
  {
    variants: {
      size: {
        default: "h-10 w-10",
        xxs: "h-4 w-4 text-[10px]",
        xsm: "h-5 w-5 text-[10px]",
        xs: "h-6 w-6 text-xs",
        sm: "h-8 w-8 text-sm",
        lg: "h-12 w-12",
        xl: "h-16 w-16",
        xxxl: "h-32 w-32"
      },
      rounded: {
        none: "rounded-none",
        xs: "rounded-xs",
        sm: "rounded-sm",
        md: "rounded-md",
        lg: "rounded-lg",
        xl: "rounded-xl",
        full: "rounded-full"
      }
    },
    defaultVariants: {
      size: "default",
      rounded: "full"
    }
  }
), _ = m.forwardRef(({ className: a = "", rounded: r, tooltip: e, size: t, ...o }, s) => {
  const d = /* @__PURE__ */ n.jsx(
    w,
    {
      ref: s,
      className: p(q({ size: t, className: a, rounded: r })),
      ...o
    }
  );
  return e ? /* @__PURE__ */ n.jsx(i.Provider, { children: /* @__PURE__ */ n.jsxs(i, { children: [
    /* @__PURE__ */ n.jsx(i.Trigger, { children: d }),
    /* @__PURE__ */ n.jsx(i.Content, { children: e })
  ] }) }) : /* @__PURE__ */ n.jsx(n.Fragment, { children: d });
});
_.displayName = w.displayName;
const S = m.forwardRef(({ className: a, ...r }, e) => /* @__PURE__ */ n.jsx(
  V,
  {
    ref: e,
    className: p("aspect-square h-full w-full object-cover", a),
    ...r
  }
));
S.displayName = "Image";
const L = m.forwardRef(({ className: a, ...r }, e) => /* @__PURE__ */ n.jsx(
  z,
  {
    ref: e,
    className: p(
      "flex h-full w-full items-center justify-center bg-muted",
      a
    ),
    ...r
  }
));
L.displayName = "Fallback";
const X = Object.assign(_, { Image: S, Fallback: L });
export {
  X as A
};
