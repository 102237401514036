import { j as x } from "./jsx-runtime-B6kdoens.js";
import { $ as y } from "./index-CWsRuimy.js";
import { c as v } from "./index-BV2fynjD.js";
import * as h from "react";
import { c as w } from "./utils-CJ9afRe1.js";
const j = v(
  "inline-flex items-center border relative border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background",
  {
    variants: {
      circle: {
        true: "rounded-full aspect-square p-0 item-center justify-center",
        false: "rounded-md"
      },
      weight: {
        default: "font-medium",
        light: "font-light",
        normal: "font-normal",
        medium: "font-medium",
        bold: "font-bold"
      },
      variant: {
        default: "bg-primary text-primary-foreground hover:bg-primary/90",
        destructive: "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline: "border border-gray-neutral-80 hover:bg-accent hover:text-accent-foreground",
        secondary: "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        ghost: "hover:bg-gray-50 hover:text-accent-foreground",
        activeBlue: "bg-blue-50 text-black-primary border border-blue-60",
        link: "underline-offset-4 hover:underline text-primary",
        text: "text-primary",
        white: "bg-white text-black-primary hover:bg-gray-100",
        dark: "bg-black-primary text-white hover:bg-black-100",
        warning: "bg-yellow-50 text-yellow-90 hover:bg-yellow-90/90",
        success: "bg-green-30 text-green-300 hover:bg-green-300/10",
        error: "bg-red-40 text-red-200 hover:bg-red-300/10",
        custom: ""
      },
      justify: {
        default: "justify-center",
        start: "justify-start",
        end: "justify-end"
      },
      spaceX: {
        default: "space-x-2",
        sm: "space-x-1",
        lg: "space-x-3",
        custom: ""
      },
      size: {
        default: "h-10 py-2 px-4 text-sm",
        sm: "h-8 px-3 text-sm",
        xs: "h-6 px-2 text-xs",
        lg: "h-11 px-8 text-sm",
        custom: ""
      },
      underline: {
        true: "underline",
        false: ""
      },
      loading: {
        true: `cursor-wait
        before:absolute z-10 before:inset-0 before:bg-inherit before:flex before:items-center before:rounded-md before:justify-center
        after:absolute after:left-1/2 after:-ml-2 z-20 after:w-4 after:h-4 after:rounded-full after:border-t-transparent after:animate-spin after:border-2 after:border-l-current after:border-b-current after:border-r-current`,
        false: ""
      },
      block: {
        true: "w-full",
        false: ""
      }
    },
    defaultVariants: {
      circle: !1,
      spaceX: "default",
      variant: "default",
      justify: "default",
      size: "default",
      weight: "default",
      loading: !1,
      block: !1,
      underline: !1
    }
  }
), k = h.forwardRef(
  ({ className: e, variant: r, justify: t, spaceX: o, weight: a, circle: n, size: s, type: i, as: f, underline: l, loading: u, block: d, children: c, asChild: b = !1, ...g }, m) => {
    const p = b ? y : f || "button";
    return /* @__PURE__ */ x.jsx(
      p,
      {
        type: i ?? "button",
        className: w(j({ justify: t, circle: n, variant: r, spaceX: o, underline: l, block: d, weight: a, size: s, loading: u, className: e })),
        ref: m,
        ...g,
        children: c
      }
    );
  }
);
k.displayName = "Button";
export {
  k as B,
  j as b
};
