import t from "react";
import { $ as b } from "./index-Cp-Nu9_j.js";
import { a as u, $ as p } from "./index-CWsRuimy.js";
function v(s) {
  const a = s + "CollectionProvider", [C, R] = b(a), [$, f] = C(a, {
    collectionRef: {
      current: null
    },
    itemMap: /* @__PURE__ */ new Map()
  }), M = (r) => {
    const { scope: e, children: l } = r, o = t.useRef(null), c = t.useRef(/* @__PURE__ */ new Map()).current;
    return /* @__PURE__ */ t.createElement($, {
      scope: e,
      itemMap: c,
      collectionRef: o
    }, l);
  }, x = s + "CollectionSlot", I = /* @__PURE__ */ t.forwardRef((r, e) => {
    const { scope: l, children: o } = r, c = f(x, l), n = u(e, c.collectionRef);
    return /* @__PURE__ */ t.createElement(p, {
      ref: n
    }, o);
  }), E = s + "CollectionItemSlot", d = "data-radix-collection-item", S = /* @__PURE__ */ t.forwardRef((r, e) => {
    const { scope: l, children: o, ...c } = r, n = t.useRef(null), m = u(e, n), i = f(E, l);
    return t.useEffect(() => (i.itemMap.set(n, {
      ref: n,
      ...c
    }), () => void i.itemMap.delete(n))), /* @__PURE__ */ t.createElement(p, {
      [d]: "",
      ref: m
    }, o);
  });
  function A(r) {
    const e = f(s + "CollectionConsumer", r);
    return t.useCallback(() => {
      const o = e.collectionRef.current;
      if (!o) return [];
      const c = Array.from(o.querySelectorAll(`[${d}]`));
      return Array.from(e.itemMap.values()).sort(
        (i, _) => c.indexOf(i.ref.current) - c.indexOf(_.ref.current)
      );
    }, [
      e.collectionRef,
      e.itemMap
    ]);
  }
  return [
    {
      Provider: M,
      Slot: I,
      ItemSlot: S
    },
    A,
    R
  ];
}
export {
  v as $
};
